import "./subscribeSearch.scss";
import { FiX } from 'react-icons/fi';
import { useState } from "react";
import { post } from "../lib/fetch";

export default function SubscribeSearch({ query }) {

    const searches = JSON.parse(localStorage.getItem("savedSearches")) ?? []
    const hasSubscribed = !!searches.find(s => s === hashCode(query))

    const [closed, setClosed] = useState(hasSubscribed);
    const [email, setEmail] = useState(localStorage.getItem("email") ?? "");

    async function submit(e) {
        e.preventDefault();
        const res = await post(`/api/subscriptions/`, { email, query })
        if (res.success) {
            localStorage.setItem("email", email)
            localStorage.setItem("savedSearches", JSON.stringify([...searches, hashCode(query)]))
            alert("Thanks for subscribing!")
            setClosed(true)
        }
    }

    function hashCode(query) {
        const s = JSON.stringify(query)
        for (var i = 0, h = 0; i < s.length; i++)
            h = Math.imul(31, h) + s.charCodeAt(i) | 0;
        return h;
    }

    // TEMP DISABLE
    // return null;

    return (
        <div className={`save-search ${closed ? "closed" : ""}`}>
            <div className="grid">
                <div>
                    <h5>Save this search</h5>
                    <p> Get alerts when new role opens. </p>
                </div>
                <div>
                    <form onSubmit={(e) => submit(e)}>
                        <input type="email"
                            placeholder="Your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required={true}
                        />
                        <button type="submit" className="subscribe"> Save </button>
                    </form>
                </div>
            </div>
            <button className="close" onClick={() => setClosed(true)}>
                <FiX />
            </button>
        </div>
    )
}