import './openingCard.scss';
import { FiCalendar, FiCheck, FiEye, FiMapPin } from "react-icons/fi";
import { TechStack } from '../Listing/TechStack';
import { formatCurrency, formatDate } from '../helpers/format';
import { CompanyLogo } from './CompanyLogo';
import { Link } from 'react-router-dom';

export default function OpeningCard({ opening, onHover, isVisited }) {

    function formatSlug(string) {
        return string.toLowerCase().replace(/[^a-z ]/g, "").trim().replace(/\s+/g, "-").slice(0, 50);
    }

    const jobUrl = `/job/${formatSlug(opening.role)}-${formatSlug(opening.company.name)}-${opening.objectID}`;

    return (
        <section onMouseEnter={(e) => onHover(opening, e.currentTarget.offsetTop)}>
            <Link to={jobUrl} className="opening" state={opening} >
                <CompanyLogo company={opening.company} />
                <div className="opening-main">
                    <span className="opening-title">
                        <h3> {opening.role} </h3>
                        <h4> {opening.company.name} </h4>
                    </span>
                    <div className="opening-info">

                        {
                            isVisited &&
                            <span className="seen" title="Seen">
                                <FiEye />
                                <FiCheck />
                            </span>
                        }

                        <span className="date">
                            {formatDate(opening.created_at)} ago
                        </span>

                        <span className="location">
                            <FiMapPin />
                            {(opening.location === "#LI-REMOTE" ? "Remote" : opening.location) || "Unknown"}
                        </span>

                        {opening.min_salary &&
                            <span className="salary-range">

                                {opening.max_salary ?
                                    `${formatCurrency(opening.currency_symbol, opening.min_salary)}
                                    -
                                    ${formatCurrency(opening.currency_symbol, opening.max_salary)}`
                                    :
                                    `> ${formatCurrency(opening.currency_symbol, opening.min_salary)}`
                                }
                            </span>
                        }

                    </div>
                </div>

                <div className="stacks">
                    {opening?.technologies?.map(stack => (
                        <TechStack stack={stack} key={stack} />
                    ))}
                </div>
                {/* </a> */}
            </Link>
        </section>
    );
}
