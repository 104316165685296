import React, { useEffect, useState } from 'react';
import HeaderBar from './components/Header/HeaderBar';
import Intro from './Intro/Intro';
import { Route, Routes, useNavigate } from "react-router-dom";
import './App.scss';
import { OpeningList } from './Listing/OpeningsList';
import ReactGA from "react-ga4";
import RolesSuggestions from './Intro/RolesSuggestions';
import Footer from './Intro/Footer';
import Opening from './Opening/Opening';

const visitedOpenings = JSON.parse(localStorage.getItem("visited")) || {};

function App() {

  const initialQuery = {
    search: "",
    location: "",
    filterByLocation: false,
    remoteOnly: false,
    stacks: []
  }

  const [query, setQuery] = useState(initialQuery);
  const [openings, setOpenings] = useState(null);

  // Controls visited openings for opened badges
  const [visited, setVisited] = useState(visitedOpenings);

  // Stores and clears timeout IDs
  const [debounceTimeout, setDebounceTimeout] = useState();

  // Overrides the debounce times and queries instantly (for abrupt changes, like toggling and clicking)
  const [debounceOverride, setDebounceOverride] = useState(false);

  // Controls whether to show the intro section or not
  const [hasQueried, setHasQueried] = useState(false);

  // Prevents the creation of new history on state changes (used on state reversal)
  const [historyLock, setHistoryLock] = useState(false);

  const navigate = useNavigate();

  function queryInputs(fieldName) {
    return {
      name: fieldName,
      value: query[fieldName],
      onChange: function (e) {
        updateQuery(fieldName, e.target.value)
      }
    }
  }

  function clearQuery() {
    updateQuery("search", "")
    setHasQueried(false)
    ReactGA.event({
      category: 'Query',
      action: 'Cleared query'
    });
  }

  function getGeoIp() {

    fetch(`/api/geoip/`)
      .then(response => response.json())
      .then(location => {
        updateQuery("location", `${location.city},${location.region},${location.country}`)
      });
  }

  function getOpenings() {

    if (query.search === "") return;

    setHasQueried(true);

    const request = {
      query: query.search,
      stacks: query.stacks,
      remoteOnly: query.remoteOnly,
    }

    if (query.filterByLocation) {
      request.location = query.location;
    }

    const queryString = new URLSearchParams(request).toString()

    updateQueryStrings();

    ReactGA.event({
      category: 'Opening',
      action: 'Load openings',
    });

    fetch(`/api/search/?${queryString}`)
      .then(response => response.json())
      .then(openings => {
        setOpenings(openings)
      });
  }

  // Update query
  function updateQuery(key, val, debounceOverride) {
    if (debounceOverride) setDebounceOverride(true);

    if (key === "location") {
      query.filterByLocation = val.length > 0
    }

    ReactGA.event({
      category: 'Query',
      action: 'Update query ' + key,
      label: val
    });

    setQuery({ ...query, [key]: val })
  }

  // LOCATION TOGGLE
  function toggleQuery(key) {
    updateQuery(key, !query[key], true)
  }

  // VISITED RELATED CODE
  function saveVisited(id, url) {
    visited[id] = Math.floor(new Date().getTime() / 1000);
    setVisited({ ...visited });
    localStorage.setItem("visited", JSON.stringify(visited))

    ReactGA.event({
      category: 'Opening',
      action: 'Click Opening',
      label: url
    });
  }

  function updateQueryStrings() {
    if (historyLock) {
      setHistoryLock(false);
      return;
    }

    const queryString = new URLSearchParams(query).toString()
    navigate({
      path: "/",
      search: queryString
    })
  }

  function restoreQuery() {
    const searchStrings = window.location.search;
    if (searchStrings !== "") {
      const newQuery = Object.fromEntries(new URLSearchParams(searchStrings))

      newQuery["stacks"] = newQuery["stacks"]?.strip?.split(",") || [];
      setDebounceOverride(true)
      setQuery({ ...query, ...newQuery })

      ReactGA.event({
        category: 'Query',
        action: 'Query restore',
        nonInteraction: true
      });

      // Due to chaining must return true
      return true;
    } else {
      setOpenings(null)
    }
  }

  function debounceSearch() {

    const waitTimeMs = 450;

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    if (debounceOverride) {
      getOpenings();
      setDebounceOverride(false);
    } else {
      const timeout = setTimeout(() => getOpenings(), waitTimeMs);
      setDebounceTimeout(timeout)
    }
  };

  function onPopState() {
    setHistoryLock(true);
    restoreQuery();
    ReactGA.event({
      category: 'Query',
      action: 'Pop State',
    });
  }

  // On Query Change Handler
  useEffect(() => {
    debounceSearch();
  }, [query])

  // On Load
  useEffect(() => {
    // Tries to restore query (i.e. from a url "?search=a&location=b") if not fetches the current location
    restoreQuery() || getGeoIp();
    window.addEventListener("popstate", onPopState);
  }, [])

  return (
    <div className="app">

      <div className="intro-header">
        {/* <div className="bg" /> */}

        <HeaderBar
          hasSearched={hasQueried}
          query={query}
          toggleQuery={toggleQuery}
          queryInputs={queryInputs}
          clearQuery={clearQuery}
          updateQuery={updateQuery}
        />

        <Intro hasResults={hasQueried} />
      </div>

      <Routes>
        <Route path="/job/:jobID" element={<Opening />} />
      </Routes>

      <OpeningList
        openings={openings}
        saveVisited={saveVisited}
        visited={visited}
        hasQueried={hasQueried}
        query={query}
        toggleQuery={toggleQuery}
        updateQuery={updateQuery}
      />
      <RolesSuggestions updateQuery={updateQuery} hasQueried={hasQueried} />
      <Footer />
    </div>
  );
}

export default App;
