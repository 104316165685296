import { FiMapPin } from 'react-icons/fi';
import { Loader } from '@googlemaps/js-api-loader';
import { useEffect, useRef, useState } from 'react';
import { MAPS_API_KEY } from '../../constants/apiKeys';


export default function LocationSearch({ query, toggleQuery, updateQuery }) {

    const [inputLocation, setInputLocation] = useState(query.location);
    const searchBox = useRef(null)

    const API_KEY = MAPS_API_KEY

    const loader = new Loader({
        apiKey: API_KEY,
        version: "weekly",
        libraries: ["places"]
    });

    function findCity(address_components) {
        const cityComponents = [
            "locality", "sublocality",
            "administrative_area_level_3",
            "administrative_area_level_2"
        ]

        let result = "";
        // Loop every component until we find a city
        cityComponents.every(component => {
            const candidate = findComponent(address_components, component);
            if (candidate) {
                result = candidate;
                return false;
            }
            return true;
        })
        return result

    }

    function findComponent(components, name) {
        return components?.find(c => c.types.indexOf(name) !== -1)?.long_name;
    }

    function boxWarning(){
        return query.filterByLocation === false || query.location === "";
    }

    useEffect(() => {

        loader.load().then((google) => {

            const options = {
                fields: ["address_components"],
                strictBounds: false,
                types: ["(cities)"],
            };

            const autocomplete = new google.maps.places.Autocomplete(
                searchBox.current,
                options
            );

            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();
                const city = findCity(place.address_components);
                const state = findComponent(place.address_components, "administrative_area_level_1") || "";
                const country = findComponent(place.address_components, "country") || "";
                const mapQuery = [city, state, country].join(",")

                const searchStrings = window.location.search;

                if (!searchStrings){
                    updateQuery("location", mapQuery, true)
                } else{
                    // Hacky way to update location without breaking search
                    const newQuery = Object.fromEntries(new URLSearchParams(searchStrings))
                    const finalQuery = {...newQuery, location: mapQuery, filterByLocation: true}
                    const finalQueryString = Object.entries(finalQuery).map(([k, v]) => `${k}=${v}`).join("&")
                    window.location.href = window.location.origin + "/?" + finalQueryString
                }
               
                setInputLocation(mapQuery)
            })
        })
    }, [])

    useEffect(() => {
        setInputLocation(query.location)
    }, [query])

    useEffect(() => {
        if (inputLocation.length === 0) {
            updateQuery("location", "", true)
        }
    }, [inputLocation])


    return (
        <div className={`fake-box location-${query.filterByLocation} box-warn-${boxWarning()}`}>
            <div className="toggler">
                <FiMapPin/>
            </div>
            <input id="input-location"
                ref={searchBox}
                placeholder="Your location..."
                type="text"
                onChange={(e) => setInputLocation(e.target.value)}
                value={inputLocation}
                onBlur={() => setInputLocation(query.location)}
            />
        </div>
    )

}