import './intro.scss';
import logo from "../logo.png";
export default function Intro({ hasResults }) {

    return (
        <>
            <div className={`intro ${hasResults ? "has-results" : ""}`}>
                <div className="container">
                    <div className="content">
                        <div className="app-logo">
                            <img src={logo} alt=''/>
                            <h1> JobTun </h1>
                        </div>
                        <h1 className="callout">
                            We found your dream job
                        </h1>
                        <h4>
                            Smart search 34239 openings in more than 3000 tech companies in milliseconds
                        </h4>
                        {/*<h5>
                        Over 32,000 <em>active</em> openings 🔥
                    </h5>
                    <span className="news">
                        🔬 Now with technology stack filter!
                    </span> */}
                    </div>
                    {/* <div className="side-dog">
                    <img alt="" src={testimg}/>
                    <div className="shadow"></div>
                </div> */}
                </div>
            </div>
        </>
    )
}