import Opening from '../components/OpeningCard';
import './openingList.scss';
import ReactGA from "react-ga4";
import { useEffect, useState } from 'react';
import CompanyTooltip from './CompanyTooltip';
import SubscribeSearch from './SubscribeSearch';
import Filters from './Filters';

export function OpeningList({ openings, saveVisited, visited, hasQueried, query, toggleQuery, updateQuery }) {

    // Company Hover tooltip info card
    const [companyTooltip, setCompanyTooltip] = useState();
    const [companyTooltipYPos, setCompanyTooltipYPos] = useState(0);
    const [sort, setSort] = useState("relevancy");

    function getResults() {
        let res = [...openings.hits];
        if (sort === "newest") {
            res = res?.sort((a, b) => b.created_at - a.created_at)
        }
        return res
    }

    function onOpeningHover(opening, yPos) {

        const company = opening.company
        setCompanyTooltipYPos(yPos)

        // Skip company updates on same company
        if (!companyTooltip || company.id !== companyTooltip.id) {
            setCompanyTooltip({ ...company })
        }

        ReactGA.event({
            category: 'Listing',
            action: 'Hover Over Listing',
            label: opening.url
        });

    }

    function LocationQueryTipBox({ query }) {
        if (query.filterByLocation === false || query.location === "") {
            return (
                <div className="warning-box">
                    <p className="main">
                        Searching is better when filtered by location!
                    </p>
                    <p className="subtext">
                        Please add your location to refine positions available to your location and timezone
                    </p>
                </div>
            )
        }
    }

    // On Query Change Handler
    useEffect(() => {
        setCompanyTooltip();
    }, [openings])


    if (!hasQueried || !openings) return null;

    return (
        <main>

            <aside>
                <CompanyTooltip companyTooltip={companyTooltip} companyTooltipYPos={companyTooltipYPos} />
            </aside>

            <article>
                <LocationQueryTipBox query={query} />
                <Filters
                    query={query}
                    toggleQuery={toggleQuery}
                    updateQuery={updateQuery}
                    sort={sort}
                    setSort={setSort}
                />

                {getResults().map(opening =>
                    <Opening
                        opening={opening}
                        key={opening.objectID}
                        onHover={onOpeningHover}
                        isVisited={!!visited[opening.objectID]}
                        saveVisited={saveVisited} />
                )}

                {
                    openings.hits?.length === 0 &&
                    <article>
                        <div className="warning-box">
                            <p className="main">
                                No jobs found with this parameters!
                                <br /><br />
                                Try to check your query for any typos and remove any filters.
                            </p>
                        </div>
                    </article>
                }
            </article>

            <SubscribeSearch query={query} />
        </main>
    )
}
