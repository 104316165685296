const jobOptions = {
    "🚀 Engineering": [
        "Software Engineer",
        "Data Scientist",
        "Data Engineer",
        "Solution Architect",
        "Security Engineer",
        "Support Engineer",
        "Engineering Manager",
        "Data Science Manager",
    ],
    "🎨 Product": [
        "Product Designer",
        "UI / UX Designer",
        "Designer Manager",
        "Program Manager",
        "Project Manager",
        "Product Manager",
    ],
    "🏢 Operations": [
        "Accountant",
        "Recruiter",
        "Sales",
        "Account Manager",
        "Marketing",
        "Assistant"
    ],
    "🔬 QA": [
        "QA Analyst",
        "QA Engineer",
        "SDET",
        "QA Manager"
    ]
}

export default jobOptions