
import { CompanyLogo } from '../components/CompanyLogo';
import './companyTooltip.scss';

export default function CompanyTooltip({ companyTooltip, companyTooltipYPos }) {

    if (!companyTooltip) return;

    return (
        <div className="company-tooltip" style={{ top: companyTooltipYPos }}>
            <div className="header">
                <CompanyLogo company={companyTooltip}/>
                <h5> {companyTooltip.name}</h5>
            </div>
            <p>
                {companyTooltip.description || "No Description yet"}
            </p>
        </div>
    )
}