import jobOptions from "../constants/jobOptions";

export default function RolesSuggestions({hasQueried, updateQuery}) {

    const sections = Object.entries(jobOptions);

    if (hasQueried) return null;
    return (
        <div className="job-list">
            {sections.map(([section, jobs]) =>
                <div key={section}>
                    <h2> {section} </h2>
                    <ul className={section}>
                        {jobs.map(job =>
                            <li onClick={() => updateQuery("search", job, true)} key={job}>
                                {job} <span className="repetition">jobs</span>
                            </li>
                        )}
                    </ul>
                </div>
            )}
        </div>
    )

}