import { FiSearch, FiX } from "react-icons/fi";
import TECH_COLORS from "../constants/techColors"
import "./techStackFilters.scss"
export default function TechStackFilters({query, updateQuery, isDropdownActive}) {

    function toggleActive(name) {

        let newActive;
        if (query.stacks.find(st => st === name)) {
            newActive = [...query.stacks.filter(st => st !== name)]
        } else {
            newActive = [...query.stacks, name]
        }
        updateQuery("stacks", newActive, true)
    }

    function isActive(name){
        return !!query?.stacks?.find(st => st === name);
    }

    const stackList = Object.entries(TECH_COLORS).map(([name, values]) => {
        const [color, backgroundColor] = values;
        return (
            <span key={name} 
                className={`stack ${isActive(name) ? "active" : ""}`} 
                onClick={() => toggleActive(name)} 
                style={{ color, backgroundColor }}>
                {name}
            </span>
        )
    })

    const chosenStacks = query.stacks.map((name) => {
        const [color, backgroundColor] = TECH_COLORS[name];
        return (
            <span key={name} 
                className={`stack ${isActive(name) ? "active" : ""}`} 
                onClick={() => toggleActive(name)} 
                style={{ color, backgroundColor }}>
                {name} <FiX/>
            </span>
        )
    })

    if (!isDropdownActive) return null;

    return (
        <div className="tech-stack-filters">
            <div className="chosen">
                <FiSearch/>
                {chosenStacks.length > 0 ?
                    chosenStacks
                    :
                    <span className="empty">
                        👇 Select some stacks below 👇
                    </span>
                }
            </div>
            <hr />
            <div className="available">
                {stackList}
            </div>
        </div>
    )

}