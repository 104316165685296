import { FaDiscord } from 'react-icons/fa';

export default function Footer() {
    return null;
    return (
        <footer>
            <div className="btn-area">
                <a href="https://discord.gg/JtGB95Dr" target="_blank" rel="noreferrer">
                    <button className="company-add">
                        <div> <FaDiscord /> Join our Discord!</div>
                    </button>
                </a>
            </div>
            <div>
            </div>
            <div>
            </div>
        </footer>
    )

}