import "./companyLogo.scss"

export function CompanyLogo({ company }) {
    if (!company){
        return null;
    }
    if (company.logo) {
        return <img className={"logo company-logo"} src={company.logo} alt={""} />;
    }

    // Company initials as placeholder
    const initials = company.name.toUpperCase().split(" ").slice(0, 2).map(name => name[0]);
    const companyHash = company.name.split("").reduce((prev, curr, i, arr) => prev + curr.charCodeAt(0), 0);

    function randomColor(seed) {
        var x = Math.sin(seed) * 10000;
        return Math.floor((x - Math.floor(x)) * 360);
    }

    const companyColor = randomColor(companyHash);

    return (
        <div className="logo logo-placeholder" style={{ "--hue": companyColor }}>
            {initials}
        </div>
    );
}
