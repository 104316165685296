import React, { useEffect, useState } from "react";
import { FiCalendar, FiExternalLink, FiMapPin, FiX } from "react-icons/fi";
import { CompanyLogo } from "../components/CompanyLogo";
import { formatCurrency, formatDate } from "../helpers/format";
import { TechStack } from "../Listing/TechStack";
import "./opening.scss";
import ReactMarkdown from 'react-markdown'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Opening() {

    let { state } = useLocation();
    const { jobID } = useParams();
    const navigate = useNavigate();

    const algoliaOID = jobID.split("-").pop()
    const [job, setJob] = useState({});
    const [mode, setMode] = useState(state ? "modal" : "solo");

    function close() {
        if (mode === "modal") {
            navigate(-1)
        } else {
            navigate("/")
        }
    }

    useEffect(() => {
        function escKey(e) {
            if (e.code === "Escape") close();
        }
        window.addEventListener("keydown", escKey)
        return () => window.removeEventListener("keydown", escKey)
    }, [])

    useEffect(() => {
        if (state) {
            setJob(state)
            setMode("modal")
        } else {
            setMode("solo")
            fetch(`/api/openings/${algoliaOID}`)
                .then(response => response.json())
                .then(res => {
                    setJob(res.data)
                });
        }
    }, [state])

    if (job === null) {
        return (
            <div className={`opening-full ${mode}-mode error-404`}
                onClick={({ target, currentTarget }) =>
                    mode === "modal" &&
                    target === currentTarget &&
                    navigate(-1)}>
                <div className="grid">
                    <div className="opening-main">
                        <span className="opening-title">
                            <h1> {404} </h1>
                            <p> This job no longer exists</p>
                        </span>
                        <button className="close" onClick={() => close()}>
                            Return
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={`opening-full ${mode}-mode`}
            onClick={({ target, currentTarget }) =>
                mode === "modal" &&
                target === currentTarget &&
                navigate(-1)}>
            <Helmet>
                <title> {`${job.role} job at ${job?.company?.name} - JSniffer`}</title>
                <meta name="description" content={job?.job_description?.slice(0, 200)} />
            </Helmet>

            <div className="grid">
                <div className="opening-main">
                    <span className="opening-title">
                        <h1> {job.role} </h1>
                        <div className="buttons">
                            <a className="apply"
                                href={job.apply_url}
                                rel="noreferrer"
                                target="_blank"
                            // onClick={() => saveVisited(opening.objectID, opening.apply_url)}
                            >
                                <button>
                                    <FiExternalLink /> Apply
                                </button>
                            </a>
                            <button className="close" onClick={() => close()}>
                                <FiX />
                            </button>
                        </div>

                    </span>
                    <div className="opening-info">

                        <span className="date">
                            <FiCalendar />
                            {formatDate(job.created_at)}
                        </span>

                        <span className="mode">
                            <FiMapPin />
                            {(job.mode === "#LI-REMOTE" ? "Remote" : job.mode) || "Unknown"}
                        </span>

                        {job.min_salary &&
                            <span className="salary-range">
                                {job.max_salary ?
                                    `${formatCurrency(job.currency_symbol, job.min_salary)}
                                    -
                                    ${formatCurrency(job.currency_symbol, job.max_salary)}`
                                    :
                                    `> ${formatCurrency(job.currency_symbol, job.min_salary)}`
                                }
                            </span>
                        }

                    </div>
                    <div className="stacks">
                        {job?.technologies?.map(stack => (
                            <TechStack stack={stack} key={stack} />
                        ))}
                    </div>
                    <div className="company-info">
                        <CompanyLogo company={job?.company} />
                        <div className="header">
                            <h5> {job?.company?.name}</h5>
                            <p>
                                {job?.company?.description}
                            </p>
                        </div>
                    </div>
                    <div className="job-description">
                        <ReactMarkdown>
                            {job.job_description}
                        </ReactMarkdown>
                    </div>
                </div>
            </div>
        </div>
    )

}