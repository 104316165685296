const TECH_COLORS = {
    ".NET": ["white", "#621f75"],
    "AWS": ["white", "#b16100"],
    "Android": ["white", "#6e8b0c"],
    "Angular": ["#ffeeee", "#c3002f"],
    "Ansible": ["white", "#c30100"],
    "Appium": ["#cceaed", "#652c90"],
    "C": ["white", "#283495"],
    "C#": ["white", "#944b90"],
    "C++": ["", "#01589d"],
    "Cypress": ["white", "#3d3d3d"],
    "Django": ["white", "#12583d"],
    "Docker": ["white", "#1f91e2"],
    "Express": ["white", "black"],
    "Go": ["white", "#307783"],
    "Google Cloud": ["white", "#4385f4"],
    "iOS": ["white", "black"],
    "Java": ["white", "#c35f00"],
    "Javascript": ["black", "#ffeb3a"],
    "Jest": ["white", "#99415a"],
    "Kotlin": ["#ffba66", "#3c368b"],
    "Kubernetes": ["white", "#326de6"],
    "Meteor": ["#d34545", "white"],
    "MongoDB": ["#00ed64", "#001f2b"],
    "NodeJS": ["#000", "#a1e537"],
    "OCaml": ["white", "#e16512"],
    "PHP": ["white", "#4e5b93"],
    "Python": ["#fccf3c", "#015599"],
    "Rails": ["white", "#cc0200"],
    "React Native": ["#61dafb", "#282c34"],
    "React": ["#61dafb", "#282c34"],
    "Ruby": ["white", "#ac0d02"],
    "Rust": ["#ffffff", "#772b0d"],
    "Scala": ["white", "#7f0b1e"],
    "Selenium": ["white", "#00b400"],
    "Spark": ["white", "#e25b1d"],
    "Spring Boot": ["white", "#67ab3b"],
    "Swift": ["white", "#f05036"],
    "TypeScript": ["white", "#3179c6"],
    "Vue": ["#50e1a1", "#35495e"],
    "Wordpress": ["white", "#00749a"],
    // "Clojure": ["", ""],
    // "Flutter": ["", ""],
    // "Haskell": ["", ""],
    // "JEE": ["", ""],
    // "Objective-C": ["", ""],
    // "Sinatra": ["", ""],
    // "Test Robot": ["", ""],
    // "systemd": ["", ""],
}

export default TECH_COLORS