import { Link } from 'react-router-dom';
import './headerBar.scss';
import LocationSearch from './LocationSearch';
import logo from "../../logo.png";

export default function HeaderBar({ hasSearched, query, queryInputs, toggleQuery, clearQuery, updateQuery }) {

    return (
        <header className={`${!hasSearched ? "no-input" : ""}`}>
            <div className="header-container">
                <div className="app-logo">
                    <Link to="/" onClick={() => clearQuery()}>
                        <img src={logo}/>
                    </Link>
                </div>
                    <div className="main-form">
                        <div className="main-question">
                            <input type="text" placeholder='Your dream role...' {...queryInputs("search")} />
                            <LocationSearch query={query} toggleQuery={toggleQuery} queryInputs={queryInputs} updateQuery={updateQuery} />
                        </div>
                    </div>
            </div>

        </header>
    )
}