import { useState } from "react";
import './filters.scss';
import TechStackFilters from '../components/TechStackFilter';
import { FiChevronDown, FiFilter } from 'react-icons/fi';

export default function Filters({ query, toggleQuery, updateQuery, sort, setSort }) {

    const [filterDropdownActive, setFilterDropdownActive] = useState(false);
    const [dropDownActive, setDropDownActive] = useState(null);

    function toggleDropdown(name) {
        if (dropDownActive !== name) {
            setDropDownActive(name)
        } else {
            setDropDownActive(null)
        }
    }

    return (
        <div className="filter-control">
            <div className={`remote-toggler remote-only-${query.remoteOnly}`} onClick={() => toggleQuery("remoteOnly")}>
                <span className='light'>
                    •
                </span>
                Remote Only
            </div>
            <div className={`filter-dropdown ${filterDropdownActive ? "active" : ""}`}>
                <button
                    className={`${filterDropdownActive ? "active" : ""}`}
                    onClick={() => setFilterDropdownActive(!filterDropdownActive)}
                >
                    <FiFilter /> Tech Stacks <FiChevronDown />
                </button>
                <TechStackFilters
                    query={query}
                    updateQuery={updateQuery}
                    isDropdownActive={filterDropdownActive}
                />
            </div>
            <div className="order-control">
                <div className="sort">
                    <button className={(sort === "relevancy") ? "active" : ""} onClick={() => setSort("relevancy")}>
                        Relevancy
                    </button>
                    <button className={(sort === "newest") ? "active" : ""} onClick={() => setSort("newest")}>
                        Newest
                    </button>
                </div>
            </div>

        </div>
    )
}