import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ReactGA from "react-ga4";
import Hotjar from '@hotjar/browser';

if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {

  Sentry.init({
    dsn: "https://4b4c36dc356344709f47b5d23394d8ef@o1373362.ingest.sentry.io/6679607",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  });

  const siteId = 3193357;
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);
  ReactGA.initialize('G-MBN5EN4XBC');
}

const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
      {/* <Opening/> */}
    </BrowserRouter>
  </React.StrictMode>
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
